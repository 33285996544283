import React, { useState, useEffect, useRef } from 'react';
import "../css/Header.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSchool, faCaretDown, faTableCells, faPhone, faBars, faNewspaper, faCamera } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const navRef = useRef();

  const handleToggleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsCollapsed(true);
    }
  };

  const handleDropdownClick = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  const handleDropdownClick2 = (isOpen2) => {
    setIsDropdownOpen2(isOpen2);
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Navbar expand="lg" id='header' ref={navRef} expanded={!isCollapsed}>
      <Navbar.Brand href="inicio">
        <img src={'assets/images/logo/colegio-lider.png'} alt="Líder" id='logo'/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" id='toggle' onClick={handleToggleClick}>
        <span id="bars"><FontAwesomeIcon icon={faBars} /></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-end ${isCollapsed ? 'hidden' : 'show'}`}>
        <Nav>
          <Nav.Link href="inicio" id='white'>
            <FontAwesomeIcon icon={faHome} /> <span id="underline">INÍCIO</span>
          </Nav.Link>
          <Nav.Link href="assets/LiderHoje.pdf" target='_blank' rel='noreferrer' id='white'>
            <FontAwesomeIcon icon={faNewspaper} /> <span id="underline">LÍDERHOJE</span>
          </Nav.Link>
          <NavDropdown 
            title={<span><FontAwesomeIcon icon={faSchool} /> <span id="underline">ENSINO E EDUCAÇÃO</span>
                <FontAwesomeIcon 
                  icon={faCaretDown} 
                  className={isDropdownOpen ? 'rotate180' : ''}
                  id='icone' 
                />
              </span>
            }  
            id="white"
            onToggle={handleDropdownClick}
          >
            <NavDropdown.Item href="ensino-fundamental-1" id='black'>
              <span id="underline">Ensino Fundamental 1</span>
            </NavDropdown.Item>
            <NavDropdown.Item href="ensino-fundamental-2" id='black'>
              <span id="underline">Ensino Fundamental 2</span>
            </NavDropdown.Item>
            <NavDropdown.Item href="ensino-medio">Ensino Médio</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="nossa-equipe">
              <span id="underline">Nossa Equipe</span>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown 
            title={<span><FontAwesomeIcon icon={faTableCells} /> <span id="underline">ACESSO</span>
                <FontAwesomeIcon 
                  icon={faCaretDown} 
                  className={isDropdownOpen2 ? 'rotate180' : ''}
                  id='icone' 
                />
              </span>
            }  
            id="white"
            onToggle={handleDropdownClick2}
          >
            <NavDropdown.Item target='_blank' href="https://aluno.escolarmanageronline.com.br/colegiolidergo">
              <span id="underline">Aluno Online</span>
            </NavDropdown.Item>
            <NavDropdown.Item target='_blank' href="https://play.google.com/store/apps/details?id=br.com.terabytesolucoes.terasmart.colegiolidergo&hl=pt_BR&gl=US">
              <span id="underline">Download Aplicativo</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item target='_blank' href="https://portaldoprofessor.escolarmanageronline.com.br/Login">
              <span id="underline">Portal do Professor</span>
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/tour360" target='_blank' rel='noreferrer' id='white'>
            <FontAwesomeIcon icon={faCamera} /> <span id="underline">TOUR 360</span>
          </Nav.Link>
          <Nav.Link href="contato" id='white'>
            <FontAwesomeIcon icon={faPhone} /> <span id="underline">CONTATO</span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
