// src/components/EnsinoMedio.js
import React, { useEffect, useState } from 'react';
import './EnsinoMedio.css';

import Header from '../../../componentes/js/Header';
import Footer from '../../../componentes/js/Footer';
import Modal from '../../../componentes/js/Modal';
import LivrosCarousel from '../../../componentes/js/LivrosCarrossel';

function EnsinoMedio() {
  const [activeCard, setActiveCard] = useState(null);
  const [livros, setLivros] = useState([]);

  useEffect(() => {
    fetch('https://server-colegiolider.onrender.com/api/livros/medio')
      .then(response => {
        if (!response.ok) {
          console.error('Erro ao buscar dados:', response.statusText);
          throw new Error('Erro ao buscar dados');
        }
        return response.json();
      })
      .then(data => {
        console.log('Dados recebidos:', data);
        setLivros(data);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);  

  const cardContents = {
    'Foco no ENEM': {
      title: 'Foco no ENEM',
      text: 'O Enem é um exame crucial para o ingresso nas universidades. No nosso Ensino Médio, damos ênfase à preparação para o Enem, com simulados, revisões e estratégias de estudo que garantem um bom desempenho dos nossos alunos.',
      imageUrl: 'assets/images/enem-logo.png',
    },
    'Projetos de Vida': {
      title: 'Projetos de Vida',
      text: 'Os Projetos de Vida são uma parte fundamental da nossa metodologia. Eles permitem que os alunos desenvolvam seus interesses e planejem suas carreiras futuras, com o suporte de nossos educadores.',
      imageUrl: 'assets/images/projeto-de-vida.jpg',
    },
    'Incentivos Científicos': {
      title: 'Incentivos Científicos',
      text: 'Estimulamos o interesse pela ciência através de projetos e iniciativas que promovem o pensamento crítico, a pesquisa e a inovação. Nossos alunos têm a oportunidade de participar de feiras de ciências e competições, desenvolvendo habilidades valiosas.',
      imageUrl: 'assets/images/incentivo-cientifico.png',
    },
    'SEE': {
      title: 'SEE',
      text: 'O Programa de Socioemocional e Ética (PSEE) é o processo pelo qual crianças, jovens e adultos adquirem e aplicam o conhecimento, as habilidades e atitudes para desenvolverem identidades  saudáveis, gerenciar emoções, alcançar metas pessoais e coletivas, sentir e demonstrar empatia pelos outros, estabelecer e manter relações solidárias bem como tomar decisões responsáveis, cuidadosas e orientadas por valores éticos.',
      imageUrl: 'assets/images/see.png',
    }
  };

  return (
    <div>
        <Header />
    <div className="escola">
      <header>
        <div className='tituloEscola'><h2 style={{ fontSize: 40 + 'px'}}>Ensino Médio</h2></div>
        <p>Sistema de Ensino</p>
          <div className='sistemaContainer'>
            <a href='https://www.sistemapoliedro.com.br/nossa-metodologia/' rel='noreferrer' target='_blank'>
              <div className='itensSistema'>
                <img src='assets/images/sistemas/poliedro.png' alt='plurall' style={{ borderRadius: 0, width: 70 + 'px' }}/>
              </div>
            </a>
          </div>
      </header>
      <section className="ensino">
        <p>O Ensino Médio desempenha um papel crucial na formação dos estudantes, oferecendo um 
        ambiente acadêmico envolvente e diagnóstico que atende às exigências do Enem. 
        Nossa equipe de professores, reconhecida por sua excelência, fornece uma base acadêmica 
        sólida que capacita os jovens a fazer escolhas informadas e a trilhar seus caminhos com 
        confiança. Através de exemplos práticos, reflexões, estímulos positivos, diálogo constante, 
        e a resolução sensata e justa de conflitos, criamos um ambiente que não apenas prepara 
        nossos alunos para serem profissionais competentes, mas também líderes éticos e solidários 
        em suas futuras comunidades.</p>
      </section>
      <section className="atividades">
        <div className="cartas">
          <div className="carta" onClick={() => setActiveCard('Foco no ENEM')}>
            <h3>Foco no ENEM</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('Projetos de Vida')}>
            <h3>Projetos de Vida</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('Incentivos Científicos')}>
            <h3>Incentivos Científicos</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('SEE')}>
            <h3>SEE</h3>
          </div>
        </div>
      </section>

      <section className="calendario">
        <h2>Calendário</h2>
        <img src='assets/images/calendarios/medio.png'></img>
      </section>

      <Modal
          show={!!activeCard}
          onClose={() => setActiveCard(null)}
          title={cardContents[activeCard]?.title}
          text={cardContents[activeCard]?.text}
          imageUrl={cardContents[activeCard]?.imageUrl}
      />

      <section className="livros">
          <LivrosCarousel livros={livros}/>
      </section>
    </div>
    <Footer />
    </div>
  );
}

export default EnsinoMedio;
