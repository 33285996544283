import React, { useEffect, useState } from 'react';
import './Livros.css';
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';
import LivrosGrid from '../../componentes/js/LivrosGrid';

const Livros = () => {
    const [livros, setLivros] = useState([]);

    useEffect(() => {
        const fetchLivros = async () => {
            try {
                const response = await fetch('https://server-colegiolider.onrender.com/api/livros/all');
                if (!response.ok) {
                    throw new Error('Erro ao buscar dados: ' + response.statusText);
                }
                const data = await response.json();
                console.log('Dados recebidos:', data);
                setLivros(data);
            } catch (error) {
                console.error('Erro:', error);
            }
        };

        fetchLivros();
    }, []);

    return (
        <div>
            <Header />
            <div className='conteudo'>
                <h2>Todos os Livros</h2>
                <LivrosGrid livros={livros} />
            </div>
            <Footer />
        </div>
    );
};

export default Livros;