import React, { useEffect, useState } from 'react';
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';
import './Equipe.css';

const Equipe = () => {
  const [professoresArray, setProfessoresArray] = useState([]);
  const [coordenadoresArray, setCoordenadoresArray] = useState([]);
  const [auxiliaresArray, setAuxiliaresArray] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchImages = async (url, setImageArray) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Erro ao buscar dados');
      }
      const data = await response.json();
      setImageArray(data.reverse());
      // Pré-carregamento
      data.forEach(image => {
        const img = new Image();
        img.src = `https://drive.google.com/thumbnail?id=${image.id}&sz=w1080`; // Mantenha a URL
      });
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  useEffect(() => {
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/coordenadores', setCoordenadoresArray);
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/professores', setProfessoresArray);
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/auxiliares', setAuxiliaresArray);
  }, []);

  const openLightbox = (imageId) => {
    setSelectedImage(`https://drive.google.com/thumbnail?id=${imageId}&sz=w1080`);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  const renderTeamSection = (title, teamArray) => (
    <div className="team-section">
      <h2>{title}</h2>
      <div className="team-grid">
        {teamArray.map((image, index) => (
          <div key={index} className="team-member" onClick={() => openLightbox(image.id)}>
            <img src={`https://drive.google.com/thumbnail?id=${image.id}&sz=w1080`} alt={`${title} ${index + 1}`} className="professor-image" />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <Header />
      {renderTeamSection('Coordenadores', coordenadoresArray)}
      {renderTeamSection('Professores', professoresArray)}
      {renderTeamSection('Auxiliares', auxiliaresArray)}

      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <img src={selectedImage} alt="Professor" className="lightbox-image" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Equipe;